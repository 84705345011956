<template>
    <tr class="clickable-row">
        <td><a href="" @click.prevent.stop>{{Utils.getDateStringFromISO(payoutTransaction.created * 1000)}}</a></td>
        <td><a href="" @click.prevent.stop>{{payoutTransaction.order.client.full_name}}</a></td>
        <td><a href="" @click.prevent.stop>
               <span v-for="item in payoutTransaction.order.items" v-bind:key="item.id">
                    {{item.title}}
                    <br>
                </span>
        </a></td>
        <td><a href="" @click.prevent.stop>{{Utils.getCurrencySymbolFromString(payoutTransaction.currency)}}{{(payoutTransaction.amount_gross/100).toFixed(2)}}</a></td>
        <td><a href="" @click.prevent.stop>{{Utils.getCurrencySymbolFromString(payoutTransaction.currency)}}{{((payoutTransaction.amount_gross/ 100).toFixed(2) - (payoutTransaction.amount / 100)).toFixed(2)}}</a></td>
        <td><a href="" @click.prevent.stop>{{Utils.getCurrencySymbolFromString(payoutTransaction.currency)}}{{(payoutTransaction.amount / 100).toFixed(2)}}</a></td>
        <td><a href="" @click.prevent.stop>{{payoutTransaction.reservation.id}}</a></td>
    </tr>
</template>

<script>
import baseMixin from "../../common/baseMixin";

export default {
    name: "PayoutRow",
    mixins: [baseMixin],
    props: {
        payoutTransaction: {
            type: Object,
            require: true
        }
    }
};
</script>
