<template>
    <ContainerWithMenu>
        <div class="page-content">
            <h1 class="h2 margin-bottom-20">Bonifico nel dettaglio</h1>
            <table class="table" v-if="payout_detail">
                <tbody>
                <tr>
                    <td style="text-align: left; width: 30%"><b>Importo</b></td>
                    <td>{{Utils.getCurrencySymbolFromString(payout_detail.currency)}}{{(payout_detail.amount/100).toFixed(2)}}</td>
                </tr>
                <tr>
                    <td style="text-align: left; width: 30%"><b>Fee</b></td>
                    <td>{{Utils.getCurrencySymbolFromString(payout_detail.currency)}}{{((payout_detail.amount/100).toFixed(2) - (payout_detail.amount_net/100).toFixed(2)).toFixed(2)}}</td>
                </tr>
                <tr>
                    <td style="text-align: left; width: 30%"><b>Netto</b></td>
                    <td>{{Utils.getCurrencySymbolFromString(payout_detail.currency)}}{{(payout_detail.amount_net/100).toFixed(2)}}</td>
                </tr>
                <tr>
                    <td style="text-align: left; width: 30%"><b>Data di creazione</b></td>
                    <td>{{Utils.getDateStringFromISO(payout_detail.created * 1000)}}</td>
                </tr>
                <tr>
                    <td style="text-align: left; width: 30%"><b>Arrivo previsto</b></td>
                    <td>{{Utils.getShortDateStringFromISO(payout_detail.arrival_date * 1000)}}</td>
                </tr>
                <tr>
                    <td style="text-align: left; width: 30%"><b>Stato</b></td>
                    <td><span :class="Utils.getLabelClassStatusPayout(payout_detail.status)" class="label">{{StringsHelper.getStatusPayout(payout_detail.status)}}</span></td>
                </tr>
                </tbody>
            </table>
            <h4>Transazioni</h4>
            <div class="table-responsive" v-if="payoutTransactions.length > 0">
                <PayoutTransactionsTable :payout-transactions="payoutTransactions"></PayoutTransactionsTable>
            </div>
            <div v-else>
                <span class="text-muted">Nessuna transazione trovata</span>
            </div>
            <div class="text-center" v-if="has_more">
                <a href="" @click.prevent.stop="showMoreCallback" class="btn btn-primary"><i class="las la-download"></i> {{ $t('common.show_more') }}</a>
            </div>
        </div>
        <SpinnerLoader v-show="loading"></SpinnerLoader>
    </ContainerWithMenu>
</template>

<script>
    import ContainerWithMenu from "../components/container/ContainerWithMenu";
    import {getPayoutDetail, getPayoutTransactions} from "../api/payments";
    import SpinnerLoader from "../components/common/SpinnerLoader";
    import PayoutTransactionsTable from "../components/Payout/PayoutTransactionsTable";
    import {LIMIT_RECORDS_API} from "../common/constants";
    import baseMixin from "../common/baseMixin";

    export default {
        title: "Bonifico nel dettaglio",
        name: "Payouts",
        mixins: [baseMixin],
        components: { ContainerWithMenu, SpinnerLoader, PayoutTransactionsTable},
        data: () => {
            return {
                payoutTransactions: [],
                payout_detail: {},
                limitPayouts: LIMIT_RECORDS_API,
                loading: false,
                has_more: false
            }
        },
        methods: {
            async getPayoutTransactions(starting_after=null) {
                this.loading = true;
                try {
                    let payoutTransactionsResponse = await getPayoutTransactions(this.$route.params.payout_id, this.limitPayouts, starting_after);
                    this.payoutTransactions = this.payoutTransactions.concat(payoutTransactionsResponse.data);
                    this.has_more = payoutTransactionsResponse.has_more;
                    this.loading = false;
                } catch (e) {
                    this.loading = false;
                    console.log(e)
                }
            },
            async getPayoutDetail() {
                this.loading = true;
                try {
                    this.payout_detail = await getPayoutDetail(this.$route.params.payout_id);
                    this.loading = false;
                } catch (e) {
                    this.loading = false;
                    console.log(e)
                }
            },
            showMoreCallback() {
                this.getPayoutTransactions(this.payoutTransactions[this.payoutTransactions.length-1].id)
            }
        },
        mounted() {
            this.getPayoutDetail();
            this.getPayoutTransactions();
        }
    };
</script>
