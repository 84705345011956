<template>
    <table class="table table-hover">
        <thead>
        <tr>
            <th>Data di creazione</th>
            <th>Cliente</th>
            <th>Descrizione</th>
            <th>Importo</th>
            <th>Fee</th>
            <th>Netto</th>
            <th>Prenotazione</th>
        </tr>
        </thead>
        <tbody>
        <PayoutTransactionRow
            :payout-transaction="payoutTransaction"
            v-for="payoutTransaction in payoutTransactions"
            v-bind:key="payoutTransaction.id"
        ></PayoutTransactionRow>
        </tbody>
    </table>
</template>

<script>

import PayoutTransactionRow from "./PayoutTransactionRow";

export default {
    name: "PayoutsTable",
    components: {PayoutTransactionRow},
    props: {
        payoutTransactions: {
            type: Array,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
